import Vue from "vue";

// read more about this approach of using mixins
// https://github.com/ktsn/vue-typed-mixins

const formatters = Vue.extend({
  methods: {
    limitDigitsNumber(val: number | null, maxDigits: number) {
      const digitsOnly = this.digitsFormatter(val);

      if (digitsOnly && digitsOnly.length > maxDigits) {
        return digitsOnly.substring(0, maxDigits);
      }

      return digitsOnly;
    },
    digitsFormatter(val: number | null | string) {
      return val?.toString().replace(/\D/g, "");
    },
    personalCodeFormatter(val: number | null | string) {
      const digitsOnly = this.digitsFormatter(val);

      if (digitsOnly && digitsOnly.length > 11) {
        return digitsOnly.substring(0, 11);
      }

      return digitsOnly;
    },
    phoneFormatter(val: number | null | string) {
      const digitsOnly = val?.toString().replace(/\D/g, "");

      if (digitsOnly && digitsOnly.length > 8) {
        return digitsOnly.substring(0, 8);
      }

      return digitsOnly;
    },
    /**
     * Use this to transform phone like 8-600-12345 to 60012345
     * @param val string | number | null
     * @param removeFirst number - number of symbols to remove from beginning
     *
     * @returns string | null
     */
    phoneFromValue(val?: string | null | number, removeFirst = 1): string | null {
      if (!val) {
        return null;
      }

      const digitsOnly = val?.toString().replace(/\D/g, "");

      if (removeFirst > 0 && digitsOnly.length > 8) {
        return digitsOnly?.substring(removeFirst) || null;
      }

      return digitsOnly || null;
    },
    /**
     * Formats phone into easy readable dashed phone number 8-623-12345
     * @param val string | number | null
     * @returns string | null
     */
    dashedPhoneFromValue(val?: string | null | number): string | null {
      if (!val) {
        return null;
      }

      const value = val.toString();
      const valueStartValidity = [
        value.length === 1 && value == "8",
        value.length === 2 && ["86", "8-"].indexOf(value) > -1,
        value.length === 3 && value != "8-6",
      ];

      if (valueStartValidity.indexOf(true) === -1 && value.length < 3) {
        return val.toString().slice(0, -1);
      }

      return val
        .toString()
        .replace(/\D/g, "")
        .replace(/^(\d{1})(\d)/, "$1-$2")
        .replace(/(\d{3})(\d{1,5})/, "$1-$2")
        .replace(/(-\d{5})\d+?$/, "$1");
    },
    fourDigitOnly(val?: string | null | number): string | null {
      if (!val) {
        return null;
      }

      // const digitsOnly = val?.toString().replace(/[^0-9-]/g, "");

      return val.toString().replace(/\D/g, "").substring(0, 4);
    },
    /**
     * This should convert 0|undefined|null|string into null or other default value
     * @param val
     * @param defaultValue
     */
    toNullOrValue(val: number | string | null | undefined, defaultValue: null | number = null): null | number {
      return !val || val === undefined || +val === 0 ? defaultValue : +val;
    },
    /**
     * This should convert 0|undefined|null|string into string or other default string value
     * @param val
     * @param defaultValue
     */
    toNullOrString(val: string | number | null | undefined, defaultValue: string): string {
      return !val || val === undefined ? defaultValue : val.toString();
    },
    /**
     * Returns 1 if string is set and not empty, else 0
     * @param val
     */
    toZeroOrOne(val: string | null) {
      return val && val.length ? 1 : 0;
    },
    /**
     * This will filter array of objects where filterKey matches filterValue
     * @param filterKey
     * @param filterValue
     * @param arrayOfOptions
     * @returns
     */
    getFilteredPropertyValue(
      filterKey: string,
      filterValue: string | number,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      arrayOfOptions: any[],
      returnProperty: null | string | number = null
    ): string | number {
      const filtered = arrayOfOptions.filter((i) => i[filterKey] == filterValue);
      if (!filtered.length) {
        return "";
      }

      return returnProperty && filtered[0][returnProperty] !== undefined ? filtered[0][returnProperty] : filtered[0];
    },
    /**
     * Returns array if array passed and if object then puts that into array and returns
     * @param value any
     * @returns []
     */
    toArrayOfObjects(value?: string | number | null) {
      if (!value) {
        return [];
      }

      if (Array.isArray(value)) {
        return value;
      }

      return [value];
    },
    validIban: (value: any): any => {
      // see https://stackoverflow.com/questions/21928083/iban-validation-check
      const rearrange = value.substring(4, value.length) + value.substring(0, 4);
      const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
      const alphaMap = {} as any[];
      const number = [] as any[];

      alphabet.forEach((value: any, index: any) => {
        alphaMap[value] = index + 10;
      });

      rearrange.split("").forEach((value: any, index: any) => {
        number[index] = alphaMap[value] || value;
      });
      const modulo = (aNumStr: any, aDiv: any): number => {
        let tmp = "" as string | number;
        let i, r;
        for (i = 0; i < aNumStr.length; i++) {
          tmp += aNumStr.charAt(i);
          r = +tmp % aDiv;
          tmp = r.toString();
        }
        return +tmp / 1;
      };

      return modulo(number.join("").toString(), 97) === 1;
    },
    priceFormatter(val?: string | null | number): string | null {
      if (!val) {
        return null;
      }

      let transformed = val.toString();
      let negative = false;
      if (transformed[0] == "-") {
        negative = true;
      }

      // drop '-' signs and convert ',' -> '.'
      transformed = transformed.replaceAll("-", "").replaceAll(",", ".");

      const firstDot = transformed.indexOf(".");
      const digitPattern = /^\d+$/;
      const transformedArray = transformed
        .split("")
        .filter((sign, index) => (sign == "." && index == firstDot && firstDot >= 1) || digitPattern.test(sign));

      return `${negative ? "-" : ""}${transformedArray.join("")}`;
    },
    quantityFormatter(val?: string | null | number): string | null {
      if (!val) {
        return null;
      }
      let transformed = val.toString();
      transformed = transformed.replaceAll(",", ".");
      const firstDot = transformed.indexOf(".");
      const digitPattern = /^\d+$/;
      const transformedArray = transformed
        .split("")
        .filter((sign, index) => (sign == "." && index == firstDot && firstDot >= 1) || digitPattern.test(sign));

      return transformedArray.join("");
    },
    dateFormatter(val?: string | null): string | null {
      if (!val) {
        return "";
      }

      const pattern = /\d{4}-(02-(0[1-9]|[12][0-9])|(0[469]|11)-(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01]))/;
      const match = val.match(pattern);
      if (match) {
        return match[0];
      }

      return val;
    },
    IBANFormatter(val?: string | null | number): string | null {
      if (!val) {
        return null;
      }
      if (val.toString().toUpperCase().substring(0, 1) != "L") {
        return null;
      }
      if (val.toString().length > 1 && val.toString().toUpperCase().substring(1, 2) != "T") {
        return val.toString().substring(0, 1);
      }
      val = val?.toString().toUpperCase().replaceAll(" ", "").replaceAll("-", "");
      if (val.toString().length > 20) {
        val = val.toString().substring(0, 20);
      }

      return this.validIban(val) ? val : val.substring(0, 19);
    },
    paymentDayFormatter(val?: string | number | null): number | null {
      if (!val || +val < 1 || +val > 31) {
        return null;
      }
      return +val;
    },
    whiteSpaceRemover(val?: string | null | number): string | null {
      return val ? val?.toString().replaceAll(" ", "") : null;
    },
    numberMaxDigits(val: any, maxDigits: number, allowMinus = false) {
      const price = this.priceFormatter(val);

      if (!price) {
        return price;
      }

      const minusRegexFragment = allowMinus ? "-?" : "";

      const pattern = `${minusRegexFragment}\\d{0,${maxDigits}}(\\.\\d{0,2})?`;
      const match = price.match(pattern);
      if (match) {
        return match[0];
      }

      return price;
    },
    currency(value: string, event: any): string {
      let newValue = value.replace(",", ".");

      // drop letters
      newValue = newValue.replace(/\p{L}+/u, "");

      if (event.type === "input") {
        const isCurrency = /^(?=.*\d)\d*(?:[.|,]\d{0,2})?$/g.test(value);

        if (!isCurrency) {
          newValue = value.slice(0, -1);
        }
      } else if (event.type === "blur") {
        const numberValue = +value;
        newValue = numberValue.toFixed(2);
      }

      return newValue;
    },
    limitedCurrency(value: string, maxDigits: number, allowMinus = false, event: any): string {
      let newValue = this.numberMaxDigits(value, maxDigits, allowMinus);
      if (!newValue) {
        newValue = "0";
      }

      newValue = newValue.replace(",", ".");

      // drop letters
      newValue = newValue.replace(/\p{L}+/u, "");

      if (event.type === "input") {
        const isCurrency = /^(?=.*\d)\d*(?:[.|,]\d{0,2})?$/g.test(value);

        if (!isCurrency) {
          newValue = value.slice(0, -1);
        }
      } else if (event.type === "blur") {
        const numberValue = +value;
        newValue = numberValue.toFixed(2);
      }

      return newValue;
    },
    currencyThousand(value: string, event: any): string | null {
      const currency = this.currency(value, event);
      return this.numberMaxDigits(currency, 4, false);
    },
    lettersOnly(value: string): string {
      return value.replace(/[)\\[\]~{}|(0123456789.#_;'`//\\,!@$%^&*=+><":?]/g, "");
    },
    houseFormatter(value: string): string {
      const match = value.match(/^\d{1,5}[A-Za-z]{0,1}/);

      if (match) {
        return match[0];
      }

      return value.substring(0, value.length - 1);
    },
    coefficientFormatter(value: string, event: any): string {
      if (!value) {
        return value;
      }

      let newValue = value.replace(",", ".");

      // drop letters
      newValue = newValue.replace(/\p{L}+/u, "");

      const isCurrency = /^(?=.*\d)\d?(?:[.|,]\d{0,5})?$/g.test(value);
      switch (event.type) {
        case "input":
          if (!isCurrency) {
            if (value.length > 7) {
              newValue = value.slice(0, -1);
            }
          }
          break;

        case "insertFromPaste":
          newValue = isNaN(parseFloat(value)) ? "" : (+parseFloat(value).toPrecision(7)).toString().slice(0, 7);
          break;
      }

      return newValue;
    },
  },
});

export default formatters;
