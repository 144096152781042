import { TranslateResult } from "vue-i18n";
import { ProposedOptionInterface } from "./applicationSettings";

export interface ApplicationInterface {
  ParaiskosID?: string;
  PardavejoID?: string;
  SutartiesNr?: string;
  PardavejoAtstovas?: string;
  Suma?: number | null; //-1
  DokumentoNr?: string | null; // null
  Trukme?: number | null; //-1
  SalyguVariantoID?: number | string | null; //-1
  AsmensKodas?: string; //"00000000000"
  Vardas?: string | null; //null
  Pavarde?: string | null; // null
  DokumentoTipoID?: number | null; // null
  SutikimasPasiulymu?: number | null;
  SutikimasPartneriu?: number | null;
  SutikimasProfiliavimo?: number | null;
  SutuoktinioSutikimasPasiulymu?: number | null;
  SutuoktinioSutikimasPartneriu?: number | null;
  SutuoktinioSutikimasProfiliavimo?: number | null;
  SutuoktinioAsmensKodas?: string;
  SutuoktinioVardas?: string;
  SutuoktinioPavarde?: string;
  SutuoktinioDokumentoTipoID?: string;
  SutuoktinioDokumentoNr?: string;
  SutuoktinioKontrolineEilute?: string;
  SutuoktinioDokumentoGaliojimoData?: string;
  Sutuoktinis?: number | string;
  Pajamos?: number;
  MazesPajamos?: number;
  BusimosPajamos?: number;
  SutuoktinioPajamos?: number;
  SutuoktinioMazesPajamos?: number;
  SutuoktinioBusimosPajamos?: number;
  KlientoVartojimoPaskola?: number;
  KlientoBustoPaskola?: number;
  Gatve?: string;
  Namas?: string;
  Butas?: string;
  Miestas?: string;
  Indeksas?: string;
  Telefonas?: string;
  Telefonas2?: string | null;
  SaskaitosNr?: string | null;
  MokejimoDiena?: number | null;
  Busena?: number | string;
  Data?: string;
  DokumentuVariantai?: string | object;
  SutuoktinioDokumentuVariantai?: string | object;
  DokumentoGaliojimoData?: string;
  SalyguVariantai?: string | object;
  MokejimuGrafikas?: string;
  Pranesimas?: string;
  Darboviete?: string;
  PasirasymoVieta?: number;
  KontrolineEilute?: string;
  ElPastas?: string;
  PajamuTipas?: number;
  Stazas?: number;
  TuriIsipareigojimu?: number;
  IsipareigojimuSuma?: number;
  PlanuojaIsipareigojimu?: number;
  PlanuojamuIsipareigojimuSuma?: number;
  KitosAplinkybes?: number;
  TuriVelavimu?: number;
  TuriIsieskojimu?: number;
  SeimyninePadetis?: number;
  SeimosNariuSkaicius?: number;
  IslaikytiniuSkaicius?: number;
  GyvenamosVietosTipas?: number;
  PajamuVariantai?: string | object;
  PiniguPanaudojimoVariantai?: string | object;
  Avansas?: number | null;
  AvansoTipas?: number;
  Valiuta?: string;
  PapildomasKodas?: string | null;
  KreditoLimitas?: number;
  MazosImokos?: number;
  PajamuPazyma?: number;
  KitiKreditai?: number;
  Pastabos?: string | null;
  Gatve2?: string;
  Namas2?: string;
  Butas2?: string;
  Miestas2?: string;
  Indeksas2?: string;
  TurtoRusys?: string | object;
  KlientoLizingoPaskola?: number;
  KlientoKitaPaskola?: number;
  VardasKorteleje?: string | null;
  AtsiemimoVietosID?: number | null;
  AtaskaitosTipas?: number;
  ParduotuvesPavadinimas?: string;
  SutartiesTipas?: number;
  SutuoktinioPajamuTipas?: number;
  SutuoktinioDarboviete?: string;
  SutuoktinioPajamuPazyma?: number;
  SutuoktinioElPastas?: string;
  SutuoktinioTelefonas?: string;
  DraudimoImoka?: number | string;
  PatikslintaDraudimoImoka?: number | string;
  DraudimoID?: number;
  DraudimoPolisas?: string;
  Refinansavimas?: string;
  Kreditoriai?: string | object;
  PapildomaSaskaita?: string | null;
  Faktura?: string;
  SalyguVariantasSarasui?: string;
  PajamuTipasSarasui?: string;
  PapildomoKreditoSuma?: number;
  DuomenuPolitikosGavimas?: number;
  KreditoNaudojimas?: number;
  DalyvaujaPolitikoje?: number;
  PolitikoVardasPavarde?: string;
  PolitikoPareigos?: string;
  PolitikoRysys?: number;
  ParaiskosPasirasymas?: number;
  SutartiesPasirasymas?: number;
  SutuoktinioParaiskosPasirasymas?: number;
  PiniguPanaudojimas?: number | string | null;
  Pilietybe?: string;
  SutuoktinioPilietybe?: string;
  TikrinimoData?: string;
  PapildomoKreditoPabaiga?: string;
  SonTGalimas?: string;
  InicijavimasGalimas?: string;
  PaskutinisPasirasymas?: string;
  Prekes?: PrekeInterfaceWrapper;
  PratestaGarantijaGalima?: string | number;
  KatalogoTipas?: string | number;
  KatalogoAkcijosIsimtis?: number;
}

export interface PrekeInterfaceWrapper {
  Preke: PrekeInterface | PrekeInterface[];
}
export interface PrekeInterface {
  PrekesID: number;
  Pavadinimas: string;
  Kiekis: number;
  Kaina: number;
  Apdrausta: number; // old insurance
  TurtoRusiesID: number;
  SerijosNr: string;
  GarantijosTrukme: number; // selected warranty duration
  GarantijosKaina: number; // calculated warranty price
  GamintojoGarantija: number; // values: GamintojoGariantijosVariantas.VariantoReiksme, API: GautiGarantijuDraudimoKainas
  Apribojimai: string | null;
}

export interface GamintojoGariantijosVariantasInterface {
  VariantoReiksme: string;
  VariantoPav: string;
}

export interface GoodsInterface {
  title: string;
  serialNo: string;
  group: number;
  price: number;
  quantity: number;
  insured: boolean; // indicates if extended warranty is used
  warrantySelected: number; // GarantijosTrukme
  warranty?: number; // GamintojoGarantija
  warrantySum?: number; // GarantijosKaina
  actionVariants?: (string | number)[]; // this will be set from product catalog API response
  id?: string | number; // this will be set from product catalog API response

  // due to backend limitations we will store actionVariants and other stuff here in a stringified json format
  // after application relaod this will by parsed and if actionVariants key is found - then it will be mapped to actionVariants prop
  limitations?: string | null;
}

export interface GoodsGroupInterface {
  RusiesID: number;
  Pavadinimas: string;
  GarantijosDraudimasGalimas: boolean;
}

export interface WarrantyExtensionPlanInterface {
  GarantijosSumuReziai: GarantijosSumuReziaiInterface;
  KiekisNuolaidai: string | number;
  NuolaidosProcentasKiekiui: string | number;
  PlanoID: string | number;
  PlanoPavadinimas: string;
}

export interface GarantijosSumuReziaiInterface {
  GarantijosSumuRezis: GarantijosSumuRezisInterface[];
}

export interface GarantijosSumuRezisInterface {
  MinSuma: string | number;
  MaxSuma: string | number;
  GarantijosKainosTrukmei: WarrantyExtensionOptionInterface[];
}

export interface WarrantyExtensionOptionInterface {
  Trukme: string | number;
  KainaProcentais: string | number;
  Tekstas?: string | TranslateResult;
}

export interface PersonalDataInterface {
  name: string;
  surname: string;
  personalCode: string | number;
  phone: string | number;
  email: string;
  addressCity?: string;
  addressStreet?: string;
  addressHouse?: string | number;
  addressFlat?: string | number;
  addressPostalCode?: string | number;
  isAddressUsageDifferent?: boolean;
  addressUsageCity?: string;
  addressUsageStreet?: string;
  addressUsageHouse?: string | number;
  addressUsageFlat?: string | number;
  addressUsagePostalCode?: string | number;
  documentType?: string;
  documentValidUntil: string | number | undefined;
  documentNumber?: string | number;
  documentControlLine?: string | number;
  citizenship: string;
  documentValidationNumber1?: number | string;
  documentValidationNumber2?: number | string;
  documentValidationNumber3?: number | string;
  documentValidationNumber4?: number | string;
  documentValidationNumber5?: number | string;
}

export interface DocumentUploadOptionInterface {
  value: number | null;
  text: string | TranslateResult;
}

export interface ParaiskosDokumentasInterface {
  DokumentoID: number;
  ParaiskosDokumentoTipas: number;
  ParaiskosID: string;
}

export interface IncomeInterface {
  income: null | number;
  incomeType: null | number;
  incomeDeclaration: number;
  incomeDecrease: number;
  incomePlanned: null | number;
  unsignedDocuments: number;
  workplace: string;
}

export interface ErrorInterface {
  KlaidosKodas: string | number;
  KlaidosPranesimas: null | string;
}

export interface GautiSiulomusVariantus2Response extends ErrorInterface {
  SiulomiVariantai: ProposedOptionInterface[];
  Refinansavimas: string;
}

export interface NumberAndArrayInterface {
  [name: number | string]: Array<number>;
}

export interface ProcessedFileInterface {
  name: string;
  documentType: number | null;
  content: string;
  extension: string;
  barCode?: string;
  thumbnails?: ProcessedFileThumbnailInterface[];
  uploaded?: boolean;
}

export interface ProcessedFileThumbnailInterface {
  page: number;
  dataUrl: string;
}

export enum ProductCatalogTypes {
  DISABLED,
  TYPE_A,
  TYPE_B,
}

export interface ContractRequestEntryInterface {
  Avansas: string;
  GaliojaIki: string;
  Laikas: string;
  LeidimoID: string;
  Pastaba: string;
  SalyguVariantoID: string;
  Suma: string;
  Sutuoktinis: string;
  Trukme: string;
  VartotojasSukures: string;
  VertinimoID: string;
  Busena: string;
  VirsesnioID: string;
}

export enum ContractRequestStatuses {
  WAITING = 0,
  CONFIRMED = 1,
  REJECTED = 2,
  ESCALATED = 3,
  EXPIRED = 4,
}
